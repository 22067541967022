.dropbody{
	position: relative;
	background: transparent;
	align-items: center;
	display: flex;
	align-items: center;
	padding: 2px;
	box-sizing: border-box;
}
.dropbody:hover{
	cursor: pointer;
}
.dropmask{
	position:fixed;
	top:0;bottom:0;left:0;right:0;
	z-index:4;
	background: rgba(0,0,0,0)
}
.selectedtext{
	width: 100%;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
}
.dropitem{
	width: 100%;
	background-color: white;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid white;
	border-radius: 5;
	box-sizing: border-box;
	z-index: 6;
	height:36px;
	color:#004A9A;
}
.dropitem:hover{
	background-color: rgba(200,200,200,1);
	font-style: bold;
	cursor: pointer;
}
.down_arrow {
  /* Browsers not below */
  transform: rotate(-45deg);
  /* Safari */
  -webkit-transform: rotate(-45deg);
  /* Firefox */
  -moz-transform: rotate(-45deg);
  /* Opera */
  -o-transform: rotate(-45deg);
  /* IE */
  -ms-transform: rotate(-45deg);
}