.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mousehover{
  opacity: 1;
}
.mousehover:hover{
  opacity: .5;
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.mousehover2{
  opacity: 1;
}
.mousehover2:hover{
  opacity: .5;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: .4vw;
  height: .4vw;               /* width of the entire scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: none;     /* color of the tracking area */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #999;    /* color of the scroll thumb */
  border-radius: 1vw;       /* roundness of the scroll thumb */
  border: .1vw solid #999;  /* creates padding around scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #999;    /* color of the scroll thumb */
  border-radius: 1vw;       /* roundness of the scroll thumb */
  border: .1vw solid #999; 
}

/*.react-resizable-handle.react-resizable-handle{
    opacity: 0;
}
*/


input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.dragcomponent .dragicons{
  opacity: 0;
  display: flex;
}

.dragcomponent:hover .dragicons{
  opacity: 1;
}



/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dark-ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.dark-ant-pagination ul,
.dark-ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dark-ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.dark-ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.dark-ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dark-ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.dark-ant-pagination-item a:hover {
  text-decoration: none;
}
.dark-ant-pagination-item:hover {
  border-color: #177ddc;
  transition: all 0.3s;
}
.dark-ant-pagination-item:hover a {
  color: #177ddc;
}
.dark-ant-pagination-item:focus-visible {
  border-color: #177ddc;
  transition: all 0.3s;
}
.dark-ant-pagination-item:focus-visible a {
  color: #177ddc;
}
.dark-ant-pagination-item-active {
  font-weight: 500;
  background: transparent;
  border-color: #177ddc;
}
.dark-ant-pagination-item-active a {
  color: #177ddc;
}
.dark-ant-pagination-item-active:hover {
  border-color: #165996;
}
.dark-ant-pagination-item-active:focus-visible {
  border-color: #165996;
}
.dark-ant-pagination-item-active:hover a {
  color: #165996;
}
.dark-ant-pagination-item-active:focus-visible a {
  color: #165996;
}
.dark-ant-pagination-jump-prev,
.dark-ant-pagination-jump-next {
  outline: 0;
}
.dark-ant-pagination-jump-prev .dark-ant-pagination-item-container,
.dark-ant-pagination-jump-next .dark-ant-pagination-item-container {
  position: relative;
}
.dark-ant-pagination-jump-prev .dark-ant-pagination-item-container .dark-ant-pagination-item-link-icon,
.dark-ant-pagination-jump-next .dark-ant-pagination-item-container .dark-ant-pagination-item-link-icon {
  color: #177ddc;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.dark-ant-pagination-jump-prev .dark-ant-pagination-item-container .dark-ant-pagination-item-link-icon-svg,
.dark-ant-pagination-jump-next .dark-ant-pagination-item-container .dark-ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.dark-ant-pagination-jump-prev .dark-ant-pagination-item-container .dark-ant-pagination-item-ellipsis,
.dark-ant-pagination-jump-next .dark-ant-pagination-item-container .dark-ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(255, 255, 255, 0.3);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.dark-ant-pagination-jump-prev:hover .dark-ant-pagination-item-link-icon,
.dark-ant-pagination-jump-next:hover .dark-ant-pagination-item-link-icon {
  opacity: 1;
}
.dark-ant-pagination-jump-prev:hover .dark-ant-pagination-item-ellipsis,
.dark-ant-pagination-jump-next:hover .dark-ant-pagination-item-ellipsis {
  opacity: 0;
}
.dark-ant-pagination-jump-prev:focus-visible .dark-ant-pagination-item-link-icon,
.dark-ant-pagination-jump-next:focus-visible .dark-ant-pagination-item-link-icon {
  opacity: 1;
}
.dark-ant-pagination-jump-prev:focus-visible .dark-ant-pagination-item-ellipsis,
.dark-ant-pagination-jump-next:focus-visible .dark-ant-pagination-item-ellipsis {
  opacity: 0;
}
.dark-ant-pagination-prev,
.dark-ant-pagination-jump-prev,
.dark-ant-pagination-jump-next {
  margin-right: 8px;
}
.dark-ant-pagination-prev,
.dark-ant-pagination-next,
.dark-ant-pagination-jump-prev,
.dark-ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(255, 255, 255, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.dark-ant-pagination-prev,
.dark-ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.dark-ant-pagination-prev button,
.dark-ant-pagination-next button {
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dark-ant-pagination-prev:hover button,
.dark-ant-pagination-next:hover button {
  border-color: #165996;
}
.dark-ant-pagination-prev .dark-ant-pagination-item-link,
.dark-ant-pagination-next .dark-ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.dark-ant-pagination-prev:focus-visible .dark-ant-pagination-item-link,
.dark-ant-pagination-next:focus-visible .dark-ant-pagination-item-link {
  color: #177ddc;
  border-color: #177ddc;
}
.dark-ant-pagination-prev:hover .dark-ant-pagination-item-link,
.dark-ant-pagination-next:hover .dark-ant-pagination-item-link {
  color: #177ddc;
  border-color: #177ddc;
}
.dark-ant-pagination-disabled,
.dark-ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.dark-ant-pagination-disabled .dark-ant-pagination-item-link,
.dark-ant-pagination-disabled:hover .dark-ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  cursor: not-allowed;
}
.dark-ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.dark-ant-pagination-disabled:focus-visible .dark-ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  cursor: not-allowed;
}
.dark-ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.dark-ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .dark-ant-pagination-options *::-ms-backdrop,
  .dark-ant-pagination-options {
    vertical-align: top;
  }
}
.dark-ant-pagination-options-size-changer.dark-ant-select {
  display: inline-block;
  width: auto;
}
.dark-ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.dark-ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: transparent;
  background-image: none;
  border: 1px solid #434343;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.dark-ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
  -moz-user-select: none;
       user-select: none;
}
.dark-ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
  -ms-user-select: none;
      user-select: none;
}
.dark-ant-pagination-options-quick-jumper input::placeholder {
  color: rgba(255, 255, 255, 0.3);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dark-ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.dark-ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.dark-ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.dark-ant-pagination-options-quick-jumper input:hover {
  border-color: #165996;
  border-right-width: 1px;
}
.dark-ant-pagination-options-quick-jumper input:focus,
.dark-ant-pagination-options-quick-jumper input-focused {
  border-color: #177ddc;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.dark-ant-pagination-options-quick-jumper input-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.dark-ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #434343;
  border-right-width: 1px;
}
.dark-ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.dark-ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #434343;
  border-right-width: 1px;
}
.dark-ant-pagination-options-quick-jumper input-borderless,
.dark-ant-pagination-options-quick-jumper input-borderless:hover,
.dark-ant-pagination-options-quick-jumper input-borderless:focus,
.dark-ant-pagination-options-quick-jumper input-borderless-focused,
.dark-ant-pagination-options-quick-jumper input-borderless-disabled,
.dark-ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.dark-ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.dark-ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.dark-ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.dark-ant-pagination-simple .dark-ant-pagination-prev,
.dark-ant-pagination-simple .dark-ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.dark-ant-pagination-simple .dark-ant-pagination-prev .dark-ant-pagination-item-link,
.dark-ant-pagination-simple .dark-ant-pagination-next .dark-ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.dark-ant-pagination-simple .dark-ant-pagination-prev .dark-ant-pagination-item-link::after,
.dark-ant-pagination-simple .dark-ant-pagination-next .dark-ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.dark-ant-pagination-simple .dark-ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.dark-ant-pagination-simple .dark-ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.dark-ant-pagination-simple .dark-ant-pagination-simple-pager input:hover {
  border-color: #177ddc;
}
.dark-ant-pagination-simple .dark-ant-pagination-simple-pager input:focus {
  border-color: #3c9be8;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}
.dark-ant-pagination-simple .dark-ant-pagination-simple-pager input[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  cursor: not-allowed;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-total-text,
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-item:not(.dark-ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-prev,
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-prev .dark-ant-pagination-item-link,
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-next .dark-ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-prev .dark-ant-pagination-item-link::after,
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-next .dark-ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-jump-prev,
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-options {
  margin-left: 2px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-options-size-changer {
  top: 0px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-mini .dark-ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.dark-ant-pagination.dark-ant-pagination-disabled {
  cursor: not-allowed;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item {
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  cursor: not-allowed;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item a {
  color: rgba(255, 255, 255, 0.3);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-active {
  background: rgba(255, 255, 255, 0.25);
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-active a {
  color: #000;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  cursor: not-allowed;
}
.dark-ant-pagination-simple.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-link {
  background: transparent;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-link-icon {
  opacity: 0;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-item-ellipsis {
  opacity: 1;
}
.dark-ant-pagination.dark-ant-pagination-disabled .dark-ant-pagination-simple-pager {
  color: rgba(255, 255, 255, 0.3);
}
@media only screen and (max-width: 992px) {
  .dark-ant-pagination-item-after-jump-prev,
  .dark-ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .dark-ant-pagination-options {
    display: none;
  }
}
.dark-ant-pagination-rtl .dark-ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.dark-ant-pagination-rtl .dark-ant-pagination-item,
.dark-ant-pagination-rtl .dark-ant-pagination-prev,
.dark-ant-pagination-rtl .dark-ant-pagination-jump-prev,
.dark-ant-pagination-rtl .dark-ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.dark-ant-pagination-rtl .dark-ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.dark-ant-pagination-rtl .dark-ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.dark-ant-pagination-rtl .dark-ant-pagination-options .dark-ant-pagination-options-size-changer.dark-ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.dark-ant-pagination-rtl .dark-ant-pagination-options .dark-ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.dark-ant-pagination-rtl.dark-ant-pagination-simple .dark-ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.dark-ant-pagination-rtl.dark-ant-pagination-simple .dark-ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.dark-ant-pagination-rtl.dark-ant-pagination.mini .dark-ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}



/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dark-ant-select-single .dark-ant-select-selector {
  display: flex;
}
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-search-input {
  width: 100%;
}
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-item,
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.dark-ant-select-single .dark-ant-select-selector::after,
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-item::after,
.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-search {
  right: 25px;
}
.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-item,
.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-placeholder {
  padding-right: 18px;
}
.dark-ant-select-single.dark-ant-select-open .dark-ant-select-selection-item {
  color: rgba(255, 255, 255, 0.3);
}
.dark-ant-select-single:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.dark-ant-select-single:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-search-input {
  height: 30px;
}
.dark-ant-select-single:not(.dark-ant-select-customize-input) .dark-ant-select-selector::after {
  line-height: 30px;
}
.dark-ant-select-single.dark-ant-select-customize-input .dark-ant-select-selector::after {
  display: none;
}
.dark-ant-select-single.dark-ant-select-customize-input .dark-ant-select-selector .dark-ant-select-selection-search {
  position: static;
  width: 100%;
}
.dark-ant-select-single.dark-ant-select-customize-input .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.dark-ant-select-single.dark-ant-select-customize-input .dark-ant-select-selector .dark-ant-select-selection-placeholder::after {
  display: none;
}
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  height: 40px;
}
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input) .dark-ant-select-selector::after,
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-item,
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  line-height: 38px;
}
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input):not(.dark-ant-select-customize-input) .dark-ant-select-selection-search-input {
  height: 38px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  height: 24px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selector::after,
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-item,
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  line-height: 22px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input):not(.dark-ant-select-customize-input) .dark-ant-select-selection-search-input {
  height: 22px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  padding: 0 7px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-search {
  right: 28px;
}
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-item,
.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-placeholder {
  padding-right: 21px;
}
.dark-ant-select-single.dark-ant-select-lg:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.dark-ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.dark-ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.dark-ant-select-multiple .dark-ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.dark-ant-select-show-search.dark-ant-select-multiple .dark-ant-select-selector {
  cursor: text;
}
.dark-ant-select-disabled.dark-ant-select-multiple .dark-ant-select-selector {
  background: #141414;
  cursor: not-allowed;
}
.dark-ant-select-multiple .dark-ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.dark-ant-select-multiple.dark-ant-select-show-arrow .dark-ant-select-selector,
.dark-ant-select-multiple.dark-ant-select-allow-clear .dark-ant-select-selector {
  padding-right: 24px;
}
.dark-ant-select-multiple .dark-ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #303030;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}
.dark-ant-select-disabled.dark-ant-select-multiple .dark-ant-select-selection-item {
  color: #595959;
  border-color: #1f1f1f;
  cursor: not-allowed;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(255, 255, 255, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove > * {
  line-height: 1;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove svg {
  display: inline-block;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove::before {
  display: none;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove .dark-ant-select-multiple .dark-ant-select-selection-item-remove-icon {
  display: block;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.dark-ant-select-multiple .dark-ant-select-selection-item-remove:hover {
  color: rgba(255, 255, 255, 0.75);
}
.dark-ant-select-multiple .dark-ant-select-selection-overflow-item + .dark-ant-select-selection-overflow-item .dark-ant-select-selection-search {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.dark-ant-select-multiple .dark-ant-select-selection-search {
  position: relative;
  max-width: 100%;
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
}
.dark-ant-select-multiple .dark-ant-select-selection-search-input,
.dark-ant-select-multiple .dark-ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.dark-ant-select-multiple .dark-ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.dark-ant-select-multiple .dark-ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.dark-ant-select-multiple .dark-ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selector::after {
  line-height: 32px;
}
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selection-search-input,
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selector::after {
  line-height: 16px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-search-input,
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-placeholder {
  left: 7px;
}
.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-search {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
}
.dark-ant-select-multiple.dark-ant-select-lg .dark-ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.dark-ant-select-disabled .dark-ant-select-selection-item-remove {
  display: none;
}
.dark-ant-select-status-error.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer) .dark-ant-select-selector {
  background-color: transparent;
  border-color: #a61d24 !important;
}
.dark-ant-select-status-error.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer).dark-ant-select-open .dark-ant-select-selector,
.dark-ant-select-status-error.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer).dark-ant-select-focused .dark-ant-select-selector {
  border-color: #a61d24;
  box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.dark-ant-select-status-warning.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer) .dark-ant-select-selector {
  background-color: transparent;
  border-color: #d89614 !important;
}
.dark-ant-select-status-warning.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer).dark-ant-select-open .dark-ant-select-selector,
.dark-ant-select-status-warning.dark-ant-select:not(.dark-ant-select-disabled):not(.dark-ant-select-customize-input):not(.dark-ant-pagination-size-changer).dark-ant-select-focused .dark-ant-select-selector {
  border-color: #d89614;
  box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.dark-ant-select-status-error.dark-ant-select-has-feedback .dark-ant-select-clear,
.dark-ant-select-status-warning.dark-ant-select-has-feedback .dark-ant-select-clear,
.dark-ant-select-status-success.dark-ant-select-has-feedback .dark-ant-select-clear,
.dark-ant-select-status-validating.dark-ant-select-has-feedback .dark-ant-select-clear {
  right: 32px;
}
.dark-ant-select-status-error.dark-ant-select-has-feedback .dark-ant-select-selection-selected-value,
.dark-ant-select-status-warning.dark-ant-select-has-feedback .dark-ant-select-selection-selected-value,
.dark-ant-select-status-success.dark-ant-select-has-feedback .dark-ant-select-selection-selected-value,
.dark-ant-select-status-validating.dark-ant-select-has-feedback .dark-ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.dark-ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector input {
  cursor: pointer;
}
.dark-ant-select-show-search.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  cursor: text;
}
.dark-ant-select-show-search.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector input {
  cursor: auto;
}
.dark-ant-select-focused:not(.dark-ant-select-disabled).dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  border-color: #177ddc;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.dark-ant-select-disabled.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  cursor: not-allowed;
}
.dark-ant-select-multiple.dark-ant-select-disabled.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector {
  background: #141414;
}
.dark-ant-select-disabled.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector input {
  cursor: not-allowed;
}
.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.dark-ant-select:not(.dark-ant-select-customize-input) .dark-ant-select-selector .dark-ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.dark-ant-select:not(.dark-ant-select-disabled):hover .dark-ant-select-selector {
  border-color: #165996;
  border-right-width: 1px;
}
.dark-ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .dark-ant-select-selection-item *::-ms-backdrop,
  .dark-ant-select-selection-item {
    flex: auto;
  }
}
.dark-ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .dark-ant-select-selection-placeholder *::-ms-backdrop,
  .dark-ant-select-selection-placeholder {
    flex: auto;
  }
}
.dark-ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.dark-ant-select-arrow > * {
  line-height: 1;
}
.dark-ant-select-arrow svg {
  display: inline-block;
}
.dark-ant-select-arrow::before {
  display: none;
}
.dark-ant-select-arrow .dark-ant-select-arrow-icon {
  display: block;
}
.dark-ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.dark-ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.dark-ant-select-arrow .anticon:not(.dark-ant-select-suffix) {
  pointer-events: auto;
}
.dark-ant-select-disabled .dark-ant-select-arrow {
  cursor: not-allowed;
}
.dark-ant-select-arrow > *:not(:last-child) {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}
.dark-ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #141414;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.dark-ant-select-clear::before {
  display: block;
}
.dark-ant-select-clear:hover {
  color: rgba(255, 255, 255, 0.45);
}
.dark-ant-select:hover .dark-ant-select-clear {
  opacity: 1;
}
.dark-ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #1f1f1f;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}
.dark-ant-select-dropdown.dark-ant-slide-up-enter.dark-ant-slide-up-enter-active.dark-ant-select-dropdown-placement-bottomLeft,
.dark-ant-select-dropdown.dark-ant-slide-up-appear.dark-ant-slide-up-appear-active.dark-ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.dark-ant-select-dropdown.dark-ant-slide-up-enter.dark-ant-slide-up-enter-active.dark-ant-select-dropdown-placement-topLeft,
.dark-ant-select-dropdown.dark-ant-slide-up-appear.dark-ant-slide-up-appear-active.dark-ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.dark-ant-select-dropdown.dark-ant-slide-up-leave.dark-ant-slide-up-leave-active.dark-ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.dark-ant-select-dropdown.dark-ant-slide-up-leave.dark-ant-slide-up-leave-active.dark-ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.dark-ant-select-dropdown-hidden {
  display: none;
}
.dark-ant-select-dropdown-empty {
  color: rgba(255, 255, 255, 0.3);
}
.dark-ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.3);
}
.dark-ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.dark-ant-select-item-group {
  color: rgba(255, 255, 255, 0.45);
  font-size: 12px;
  cursor: default;
}
.dark-ant-select-item-option {
  display: flex;
}
.dark-ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dark-ant-select-item-option-state {
  flex: none;
}
.dark-ant-select-item-option-active:not(.dark-ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.dark-ant-select-item-option-selected:not(.dark-ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  background-color: #111b26;
}
.dark-ant-select-item-option-selected:not(.dark-ant-select-item-option-disabled) .dark-ant-select-item-option-state {
  color: #177ddc;
}
.dark-ant-select-item-option-disabled {
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}
.dark-ant-select-item-option-disabled.dark-ant-select-item-option-selected {
  background-color: #141414;
}
.dark-ant-select-item-option-grouped {
  padding-left: 24px;
}
.dark-ant-select-lg {
  font-size: 16px;
}
.dark-ant-select-borderless .dark-ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.dark-ant-select.dark-ant-select-in-form-item {
  width: 100%;
}
.dark-ant-select-rtl {
  direction: rtl;
}
.dark-ant-select-rtl .dark-ant-select-arrow {
  right: initial;
  left: 11px;
}
.dark-ant-select-rtl .dark-ant-select-clear {
  right: initial;
  left: 11px;
}
.dark-ant-select-dropdown-rtl {
  direction: rtl;
}
.dark-ant-select-dropdown-rtl .dark-ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.dark-ant-select-rtl.dark-ant-select-multiple.dark-ant-select-show-arrow .dark-ant-select-selector,
.dark-ant-select-rtl.dark-ant-select-multiple.dark-ant-select-allow-clear .dark-ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.dark-ant-select-rtl.dark-ant-select-multiple .dark-ant-select-selection-item {
  text-align: right;
}
.dark-ant-select-rtl.dark-ant-select-multiple .dark-ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.dark-ant-select-rtl.dark-ant-select-multiple .dark-ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.dark-ant-select-rtl.dark-ant-select-multiple .dark-ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.dark-ant-select-rtl.dark-ant-select-multiple.dark-ant-select-sm .dark-ant-select-selection-placeholder {
  right: 7px;
}
.dark-ant-select-rtl.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-item,
.dark-ant-select-rtl.dark-ant-select-single .dark-ant-select-selector .dark-ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-item,
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-show-arrow .dark-ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-search {
  right: 6px;
}
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-item,
.dark-ant-select-rtl.dark-ant-select-single.dark-ant-select-sm:not(.dark-ant-select-customize-input).dark-ant-select-show-arrow .dark-ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}

